import { Injectable } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { Subject } from 'rxjs';

export class PwaUpdateEvent {
  /** PWA更新先有り */
  public static readonly eventCheckForUpdateFound = 'CHECK_FOR_UPDATE_FOUND';
  /** PWAの更新確認に異常が発生 */
  public static readonly eventCheckForUpdateFailed = 'CHECK_FOR_UPDATE_FAILED';
  /** PWAの更新先無し */
  public static readonly eventCheckForUpdateNotFound =
    'CHECK_FOR_UPDATE_NOT_FOUND';
  /** PWAの更新完了 */
  public static readonly eventUpdateComplete = 'UPDATE_COMPLETED';
  /** PWAの更新失敗 */
  public static readonly eventUpdateFailed = 'UPDATE_FAILED';
}

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  public checkUpdateFinish = new Subject();
  private swUpdate: SwUpdate;

  /**
   * コンストラクタ
   *
   * @param swUpdate AngularServiceWorker更新インスタンス
   */
  constructor() {
  }

  public setSwUpdate(swUpdate: SwUpdate){
    this.swUpdate = swUpdate;
  }

  /**
   * ServiceWorker更新購読開始
   */
  public setUpSwUpdateSubscriber(): void {
    if (!this.swUpdate.isEnabled) {
      console.log('disabled ServiceWorker.');
      return;
    }

    this.swUpdate.versionUpdates.subscribe((event: VersionEvent) => {
      switch (event.type) {
        case 'VERSION_INSTALLATION_FAILED':
          console.error('VERSION_INSTALLATION_FAILED: ', event.error);
          break;
        case 'VERSION_READY':
          console.log('swupdate currentVersion: ', event.currentVersion.hash);
          console.log('swupdate latesVersion: ', event.latestVersion.hash);
          break;
        case 'VERSION_DETECTED':
          console.log('swupdate found: ', event.version.hash);
          break;
        default:
          break;
      }
    });
  }

  /**
   * PWA更新確認を行う
   */
  public async pwaUpdateConfirm(): Promise<PwaUpdateEvent> {
    if (this.swUpdate.isEnabled) {
      return await this.swUpdate
        .checkForUpdate()
        .then((value: boolean) => {
          console.log('check for update result: ', value);
          if (value) {
            return PwaUpdateEvent.eventCheckForUpdateFound;
          } else {
            return PwaUpdateEvent.eventCheckForUpdateNotFound;
          }
        })
        .catch((error: any) => {
          // PWA更新確認に異常が発生
          console.log('Check For Update Reject Reason: ', error);
          return PwaUpdateEvent.eventCheckForUpdateFailed;
        });
    }
  }

  /**
   * PWA更新を行う
   */
  public async pwaUpdate(): Promise<PwaUpdateEvent> {
    return await this.swUpdate
      .activateUpdate()
      .then((value: boolean) => {
        console.log('activate update result: ', value);
        if (value) {
          return PwaUpdateEvent.eventUpdateComplete;
        } else {
          return PwaUpdateEvent.eventUpdateFailed;
        }
      })
      .catch((reason: any) => {
        /** PWA更新に失敗した場合 */
        console.error('activateUpdate error: ', reason);
        return PwaUpdateEvent.eventUpdateFailed;
      });
  }
}
