/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { LanguageSetup } from './language-setup';
import { HttpRequestor } from './http-requestor';
import { LocationTracker } from './location-tracker';
import { UserStatusPolling } from './user-status/user-status-polling';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserStatusManager {
  public dispatchConfirmationResPonse = new Subject();

  private strategy: UserStatusPolling;
  private userInfo: any;

  constructor(
    public langSetup: LanguageSetup,
    public httpReq: HttpRequestor,
    public locationTracker: LocationTracker,
  ) {
    this.strategy = new UserStatusPolling(this.langSetup, this.httpReq, this.locationTracker);
  }


  getUserStatus(): any {
    return this.userInfo;
  }

  /** 初期化処理はGPSのトラッキングの開始後に行うこと。 */
  initialize(userId: string) {
    this.strategy.start(this.statusReceiveCallback, userId);
  }

  /** resDataはユーザステータス取得APIの確認時のレスポンスの形式で返却すること。 */
  statusReceiveCallback = (resData: any) => {
    this.userInfo = resData;
    this.dispatchConfirmationResPonse.next(resData);
  };


  finalize() {
    this.strategy.stop();
  }
}
