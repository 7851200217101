import { Component, ViewEncapsulation } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AlertButton, AlertController, AlertOptions } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Environment } from 'src/environments/environment';
import { PwaUpdateEvent, UpdateService } from '../../services/update-service';

@Component({
  selector: 'app-comp-updates',
  templateUrl: 'comp-updates.html',
  styleUrls: ['comp-updates.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CompUpdatesAlertComponent {

  /** 更新確認中タイマー */
  private timeoutCheckForUpdate: any;
  /** 更新中タイマー */
  private timeoutUpdating: any;

  /** 表示なし */
  private readonly alertIdNone = 'NONE';
  /** 更新確認中 */
  private readonly alertIdCheckForUpdate = 'ALERT_CHECK_FOR_UPDATE';
  /** 更新実行確認 */
  private readonly alertIdUpdateConfirm = 'ALERT_UPDATE_CONFIRM';
  /** 更新中 */
  private readonly alertIdUpdating = 'ALERT_UPDATING';
  /** 更新失敗 */
  private readonly alertIdFailed = 'ALERT_FAILED';

  /** 現在表示画面 */
  private isShowAlertId = this.alertIdNone;

  constructor(
    private alertCtrl: AlertController,
    private swUpdate: SwUpdate,
    private translate: TranslateService,
    private updateService: UpdateService

  ) {
    this.updateService.setSwUpdate(this.swUpdate);
    this.updateService.setUpSwUpdateSubscriber();
  }

  /**
   * 更新確認中の画面生成
   */
  public async checkForUpdateView(): Promise<void> {
    this.translate
      .get(['check_for_pwa_updates'])
      .subscribe(async (valueMsg: any) => {
        await this.showAlert(
          this.alertIdCheckForUpdate,
          valueMsg.check_for_pwa_updates
        );
        /** s3バージョン番号チェック結果 */
        const isLatest = await this.checkVersionNoForS3();
        setTimeout(async () => {
          if (isLatest) {
            if (this.isShowAlertId !== this.alertIdNone) {
              // 表示中のアラートが存在する場合、表示されてるアラートを閉じる
              await this.alertCtrl
                .dismiss(undefined, undefined, this.isShowAlertId)
                .catch(() => {
                  console.log(`${this.isShowAlertId} closing`);
                });
              this.isShowAlertId = this.alertIdNone;
            }
            //自動ログイン起動
            this.updateService.checkUpdateFinish.next();
          } else {
            await this.checkForUpdates();
          }
        }, Environment.pwaDisplayTimeout);
      });
  }

  /**
   * 更新実行確認の画面生成
   */
  public async updateConfirmView(): Promise<void> {
    this.translate
      .get(['detect_new_version_of_pwa'])
      .subscribe((valueMsg: any) => {
        this.translate.get(['ok']).subscribe(async (valueBtn: any) => {
          const alertButtons: AlertButton[] = [
            {
              text: valueBtn.ok,
              role: 'ok',
              cssClass: 'alert-button-confirm',
              handler: () => this.updatingView(),
            },
          ];
          await this.showAlert(
            this.alertIdUpdateConfirm,
            valueMsg.detect_new_version_of_pwa,
            alertButtons
          );
        });
      });
  }

  /**
   * 更新処理中状態
   *
   * @returns true:更新処理中 , false: 更新未動作
   */
  public isUpdating(): boolean {
    if (this.isShowAlertId === this.alertIdNone) {
      return false;
    }
    return true;
  }

  /**
   * 更新中の画面生成
   */
  private updatingView(): void {
    this.translate.get(['updating_pwa']).subscribe(async (valueMsg: any) => {
      await this.showAlert(this.alertIdUpdating, valueMsg.updating_pwa);
      await this.update();
    });
  }

  /**
   * 更新失敗時の画面生成
   */
  private retryCheckForUpdateView(): void {
    this.translate.get(['update_failed']).subscribe((valueMsg: any) => {
      this.translate.get(['ok']).subscribe(async (valueBtn: any) => {
        const retryButtons: AlertButton[] = [
          {
            text: valueBtn.ok,
            role: 'ok',
            cssClass: 'alert-button-confirm',
            handler: () => {
              //ブラウザ更新
              document.location.reload();
            },
          },
        ];
        await this.showAlert(
          this.alertIdFailed,
          valueMsg.update_failed,
          retryButtons
        );
      });
    });
  }

  /**
   * 更新確認
   */
  private async checkForUpdates(): Promise<void> {
    if (!this.swUpdate.isEnabled) {
      return;
    }

    // タイムアウトで、更新異常と判断し画面失敗を画面を表示する
    this.timeoutCheckForUpdate = setTimeout(() => {
      this.retryCheckForUpdateView();
    }, Environment.pwaCheckForUpdateTimeout);

    // 更新確認実行
    const evt = await this.updateService.pwaUpdateConfirm();
    /** s3バージョン番号チェック結果 */
    const isLatest = await this.checkVersionNoForS3();

    if (evt === PwaUpdateEvent.eventCheckForUpdateFound) {
      // 更新が見つかったので、更新実行確認する
      if (this.timeoutCheckForUpdate) {
        clearTimeout(this.timeoutCheckForUpdate);
      }
      this.updateConfirmView();

    } else if (evt === PwaUpdateEvent.eventCheckForUpdateNotFound && isLatest) {
      // 更新先が見つからないかつs3のバージョン番号と一致したので終了する
      if (this.timeoutCheckForUpdate) {
        clearTimeout(this.timeoutCheckForUpdate);
      }
      await this.showAlert(this.alertIdNone);

    } else if (evt === PwaUpdateEvent.eventCheckForUpdateFailed || !isLatest) {
      // 更新確認実行に失敗、もしくはs3のバージョン番号と一致しないので、再試行する
      if (this.timeoutCheckForUpdate) {
        clearTimeout(this.timeoutCheckForUpdate);
      }
      this.retryCheckForUpdateView();
    }
  }

  /**
   * 更新実行
   */
  private async update(): Promise<void> {
    setTimeout(async () => {

      // タイムアウトで、更新失敗表示
      this.timeoutUpdating = setTimeout(
        () => this.retryCheckForUpdateView(),
        Environment.pwaUpdatingTimeout
      );
      // 更新実行
        const evt = await this.updateService.pwaUpdate();
      if (evt === PwaUpdateEvent.eventUpdateComplete) {
        if (this.timeoutUpdating) {
          clearTimeout(this.timeoutUpdating);
        }
        //ブラウザ更新
        document.location.reload();
      } else if (evt === PwaUpdateEvent.eventUpdateFailed) {
        // 更新中に異常が発生したので、更新確認から再試行
        if (this.timeoutUpdating) {
          clearTimeout(this.timeoutUpdating);
        }
        this.retryCheckForUpdateView();
      }
    }, Environment.pwaDisplayTimeout);
  }

  /**
   * 画面表示
   *
   * @param alertId 表示する画面ID
   * @param msg 画面に表示する文言
   * @param alertButtons 画面に表示するボタン
   */
  private async showAlert(
    alertId: string,
    msg?: string,
    alertButtons?: AlertButton[]
  ): Promise<void> {

    if (Environment.operationDuringPwaUpdate && alertId === this.alertIdCheckForUpdate) {
      return;
    }
    // 画面情報生成
    const alertOption: AlertOptions = {
      id: alertId,
      message: msg,
      buttons: alertButtons,
      translucent: true,
      backdropDismiss: Environment.operationDuringPwaUpdate,
      animated: false,
    };

    // 遷移先画面表示
    if (alertId !== this.alertIdNone) {
      const alert = await this.alertCtrl.create(alertOption);
      await alert.present();
    }

    if (this.isShowAlertId !== this.alertIdNone) {
      // 後ろに表示中のアラートが存在する場合、表示されてるアラートを閉じる
      await this.alertCtrl
        .dismiss(undefined, undefined, this.isShowAlertId)
        .catch(() => {
          console.log(`${this.isShowAlertId} closing`);
        });
    }
    // 表示中の画面IDを更新
    this.isShowAlertId = alertId;
  }

  /**
   * s3にバージョン番号ファイルとEnvironmentのバージョン番号が一致するか確認する
   *
   * @returns チェック結果 一致：true、不一致: false
   */
  private checkVersionNoForS3(): Promise<boolean> {
    return this.getVersionNoForS3().then(s3Ver => s3Ver === Environment.appVersion);
  }

  /**
   * s3のバージョン番号ファイルの値を取得する
   *
   * @returns s3から取得したバージョン番号
   */
  private async getVersionNoForS3(): Promise<string> {
    const resStr = await fetch(Environment.addressVersionUrl, {
      method: 'GET'
    }).then(res => res.text());
    return resStr;
  }
}
